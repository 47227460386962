.latte-content {
    font-size: 0;

    display: inline-block;
    overflow: hidden;

    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;

    border: 0;
}
