.latte-carousel {
    font-size: 0;

    position: relative;

    display: inline-block;

    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0;

    user-select: none;

    border: 0;

    -webkit-user-drag: none;
    -moz-user-drag: none;
}
