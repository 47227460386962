.latte-stage {
    font-size: 0;

    position: relative;

    display: inline-block;

    box-sizing: border-box;
    margin: 0;
    padding: 0;

    border: 0;
}
