/**
 * CAROUSEL
 *
 * Brief description of the component here.
 */

/* ------------------------------------ *\
   #CAROUSEL
\* ------------------------------------ */
.n7-carousel {
    &__slide-content {
        flex-direction: column;
        max-width: $carousel-content-width;
        margin: 0 auto;

        // display: flex;
        // width: 100%;
        // padding: 3px;
    }

    &__slide-content-right {
        display: block;

        // flex-grow: 1;
        // display: flex;
        // justify-content: flex-end;
        // align-items: flex-end;
        // padding: 3px;
    }

    &__slide {
        position: relative;
        width: 100%;
        height: $carousel-height;
        background-repeat: no-repeat;
        background-position: right center;
        background-size: cover;
    }

    &__slide-content-wrapper {
        max-width: 100%;

        // position: relative;
        // display: flex;
        // align-items: center;
        // height: 100%;
        // max-width: $carousel-content-width;
        // margin: 0 auto;
        // z-index: 2;
    }

    &__slide-content-left {
        max-width: 65%;

        // padding: 3px;
    }

    &__slide-title {
        font-size: $font-size-xxxl;
        text-shadow: 0 2px 10px rgb(0 0 0 / 8%);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
            color: $color-text-inverse !important;
            font-size: $font-size-l;
        }

        // display: block;
        // margin-bottom: $space*2;
        // font-family: $font-family-headings;
        // font-size: $font-size-xxxl;
        // color: $color-text-inverse;
    }

    &__slide-text {
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        a {
            text-shadow: 0 2px 10px rgb(0 0 0 / 25%);
            color: $color-text-inverse !important;
        }

        em {
            font-size: inherit;
        }

        // display: block;
        // margin-bottom: $space*2;
        // font-family: $font-family-main;
        // font-size: $font-size-l;
        // color: $color-text-inverse;

        h1 {
            font-size: $font-size-xxl;
            font-style: normal;
            font-weight: $font-weight-normal;
            line-height: 1.5;
            text-shadow: 0 2px 10px rgb(0 0 0 / 25%);

            em {
                font-size: inherit;
                font-style: italic;
                font-weight: $font-weight-normal;
            }
        }

        h2 {
            font-size: $font-size-l;
            font-style: normal;
            font-weight: $font-weight-normal;
            line-height: 1.4;
            text-shadow: 0 2px 10px rgb(0 0 0 / 25%);

            em {
                font-size: $font-size-l;
                font-style: italic;
                font-weight: $font-weight-normal;
            }
        }

        h5 {
            margin-bottom: $space * 2;
            font-size: $font-size-s;

            // font-style: italic;
            font-weight: $font-weight-normal;
            text-shadow: 0 2px 10px rgb(0 0 0 / 25%);
        }

        h6 {
            position: absolute;
            bottom: $space * 2;
            right: $space;
            max-width: 200px;
            text-align: right;
            margin-top: $space * 2;
            padding: $space;
            background-color: rgb(0 0 0 / 75%);
            border-radius: $border-radius;
            text-shadow: 0 0 10px rgb(0 0 0 / 40%);
            font-size: $font-size-xxs;
            font-style: normal;
            font-weight: $font-weight-normal;
            color: $color-text-inverse !important;
        }
    }

    &__slide-metadata-wrapper {
        // ...
    }

    &__slide-metadata {
        // color: $color-text-inverse;
    }

    &__slide-metadata-label {
        // margin-right: $space/2;
    }

    /* ------------------------------------ *\
       #SLIDE-WITH-IMAGE
    \* ------------------------------------ */
    &__slide.has-image {
        /* Overlay */
        &::after {
            content: ' ';
            position: absolute;
            inset: 0;
            background-color: rgb(0 0 0 / 0%);
            z-index: 1;
        }
    }
}

/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */
@media all and (max-width: $breakpoint-laptop) {
    // ...
}

@media all and (max-width: $breakpoint-ipad-portrait) {
    // ...
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
    // ...
}

/* Retina */
@media (resolution >= 2), (resolution >= 192dpi) {
    // ...
}
