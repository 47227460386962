.latte-item {
    font-size: 0;

    display: inline-block;
    overflow: hidden;

    box-sizing: border-box;
    margin: 0;
    padding: 0;

    border: 0;

    * {
        font-size: initial;
    }
}
