.latte-dots {
    position: absolute;
    bottom: -25px;
    left: 0;

    display: inline-block;

    box-sizing: border-box;
    width: 100%;

    text-align: center;

    &.invisible {
        display: none;
    }

    .latte-dot {
        display: inline-block;

        box-sizing: border-box;
        width: 15px;
        height: 15px;
        margin: 5px;

        cursor: pointer;

        border-radius: 15px;
        background-color: #dddddd;

        &.active {
            background-color: #aaaaaa;
        }
    }
}
